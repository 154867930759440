<template>
  <div class="track">
    <Split class="container"
      direction="column"
      canMaximize
      :padding="0"
      :sideOffset="0.58"
      @trigger="initHeight">
      <template #first>
        <el-button type="primary"
          class="search"
          :icon="searchVisible ? 'el-icon-close' : 'el-icon-search'"
          :title="!searchVisible ? '展开搜索框' : '收起搜索框'"
          circle
          @click="changeSearch"></el-button>
        <div class="search-pane"
          v-show="searchVisible">
          <SelectGroupTree placeholder="设备名称/终端号"
            ref="refSelectGroup"
            :multipleProp="{ noGroup: true }"
            :inputGroup="false"
            @on-select="onSelect"
            @on-vehicle="onConfirm"
            :disabled="inputDisabled"
            :selecteds="selecteds" />
          <div class="search-pane-item"
            style="margin-top: 10px">
            <label>选择时间:</label>
            <el-button @click="changeDate(0)"
              type="text">今日</el-button>
            <el-button @click="changeDate(1)"
              type="text">昨日</el-button>
            <el-button @click="changeDate(2)"
              type="text">近三天</el-button>
            <el-button @click="changeDate(6)"
              type="text">近七天</el-button>
          </div>
          <div class="search-pane-item">
            <label>开始时间:</label>
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
              v-model="conditionObj.beginTime"
              type="datetime"
              placeholder="选择日期时间"></el-date-picker>
          </div>
          <div class="search-pane-item">
            <label>结束时间:</label>
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
              v-model="conditionObj.endTime"
              type="datetime"
              placeholder="选择结束时间"></el-date-picker>
          </div>
          <div class="search-pane-item">
            <label>停车时间:</label>
            <div class="parking-item">
              <a>></a>
              <el-input v-model="conditionObj.filterTime"
                min="0"
                maxlength="9"
                @input="changeInput"
                :disabled="!stopStatus"
                type="number"
                placeholder="请输入内容"></el-input>
              <span>分钟</span>
            </div>
          </div>
          <div class="search-pane-item">
            <label>定位数据:</label>
            <el-select v-model="conditionObj.posStatus"
              placeholder="请选择"
              style="width: 63%">
              <el-option label="全部"
                :value="1"></el-option>
              <el-option label="过滤不定位轨迹"
                :value="0"></el-option>
              <el-option label="过滤定位轨迹"
                :value="2"></el-option>
            </el-select>
          </div>
          <div class="search-pane-item">
            <el-checkbox v-model="stopStatus">过滤停车轨迹</el-checkbox>
          </div>
          <div class="search-btn">
            <el-button @click="searchClick"
              :loading="isSearching"
              type="primary"
              icon="el-icon-search">查询</el-button>
          </div>
        </div>
        <div class="play-pane">
          <!-- <label>回放进度</label>
          <el-progress :percentage="percentage"></el-progress> -->
          <div class="speed-str">
            <el-popover placement="bottom"
              trigger="click">
              <el-select style="width: 150px; margin: 0 auto"
                v-model="speedValue"
                placeholder="请选择">
                <el-option label="一般"
                  :value="1200"></el-option>
                <el-option label="快"
                  :value="3200"></el-option>
                <el-option label="慢"
                  :value="200"></el-option>
              </el-select>

              <span slot="reference">
                {{
                { 1200: "一般", 3200: "快", 200: "慢" }[speedValue]
                }}
              </span>
            </el-popover>
          </div>
          <el-button size="mini"
            type="primary"
            :disabled="activeName !== '1'"
            @click="playTrack">播放</el-button>
          <el-button size="mini"
            type="primary"
            @click="pauseMove">暂停</el-button>
        </div>
        <AMap :center="mapCenter"
          :zoom="17">
          <!-- 展示地图信息窗体 -->
          <InfoWindow v-if="trackProps.path"
            v-model="infoProps.visible"
            :lngLat="windowPoint"
            :offset="[0, -26]"
            :width="350"
            :height="210"
            :showClose="true">
            <div class="window-title">{{ infoProps.params.devTime || "--" }}</div>
            <div class="window-info">
              <label>
                设备名称码
                <strong>{{ infoProps.params.plate || "--" }}</strong>
              </label>
              <label>
                定位经度
                <strong>{{ infoProps.params.lon || "--" }}</strong>
              </label>
              <label>
                行驶速度
                <strong>{{ infoProps.params.sensorSpeed || infoProps.params.gpsSpeed }}</strong>
              </label>
              <label>
                定位纬度
                <strong>{{ infoProps.params.lat || "--" }}</strong>
              </label>
              <label>
                行驶方向
                <strong>{{ infoProps.params.directionStr || "--" }}</strong>
              </label>
              <label>
                <!--  定位海拔
                <strong>{{infoProps.params.high || "--"}}</strong>-->
              </label>
              <label style="width: 100%">
                地理位置
                <strong>
                  {{ infoProps.params.address }}
                  <!-- <AddressText
                    style="display: inherit;"
                    v-model="infoProps.address"
                    :lng="infoProps.lngLat[0]"
                    :lat="infoProps.lngLat[1]"
                  ></AddressText>-->
                </strong>
              </label>
            </div>
          </InfoWindow>

          <!-- 轨迹回放 -->
          <MapTrack ref="mapTrackRef"
            :path="trackProps.path"
            :speed="speedValue"
            :markerOpts="markerOpts"
            :startMarkerOpts="markerOpts"
            :endMarkerOpts="markerOpts"
            @moving="movingClick"
            @moveEnd="onMoveEnd">
            <template #moveMarker>
              <Iconfont :name="vehicleIconName('icon-a-zu517')"
                :size="carInfo.machineryProductType ? 48: 32"
                :height="52"
                :rotate="infoProps.params.direction || 0"></Iconfont>
            </template>
            <template #startMarker>
              <Iconfont name="icon-qi"
                :size="32"></Iconfont>
            </template>
            <template #endMarker>
              <Iconfont name="icon-zhong"
                :size="32"></Iconfont>
            </template>
          </MapTrack>
        </AMap>
      </template>
      <template #second>
        <div class="footer">
          <el-tabs v-model="activeName"
            @tab-click="handleClick">
            <el-tab-pane label="轨迹数据"
              name="1"></el-tab-pane>
            <el-tab-pane label="停车数据"
              name="2"></el-tab-pane>
            <el-tab-pane label="不定位数据"
              name="3"></el-tab-pane>
          </el-tabs>

          <div ref="umyTable"
            class="table-umy">
            <u-table ref="uTableObj"
              :data="tableData"
              :height="height"
              use-virtual
              showHeaderOverflow="title"
              :row-height="rowHeight"
              border>
              <u-table-column type="index"
                width="80"
                fixed
                label="序号" />
              <u-table-column v-for="item in columns"
                :key="item.prop"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed"
                :width="item.width" />
              <u-table-column prop="voltage"
                width="120"
                label="电压">
                <template v-slot="{ row }">{{row.voltage || 0}}V</template>
              </u-table-column>

              <u-table-column prop="address"
                width="400"
                show-overflow-tooltip
                label="地址">
                <!-- <template v-slot="{ row }">
                  <AddressText
                    v-model="row.address"
                    :lnglat="[row.lon, row.lat]"
                  ></AddressText>
                </template>-->
              </u-table-column>
            </u-table>
          </div>
        </div>
      </template>
    </Split>
  </div>
</template>

<script>

/**
 * 轨迹回放组件
 */

import { throttle } from 'lodash';
import GroupTree from "@/components/GroupTree";
import SelectGroupTree from "@/components/GroupTree/SelectGroupTree";
import Split from "@/components/Split";
import { getTrackInfo } from "@/api/getManagementData.js";
import dayjs from "dayjs";
import AMap, { MapTrack, InfoWindow, MapClusterer } from "@/components/AMap";
import AddressText from "@/components/AddressText";
import { getAddresses } from "@/components/AMap/utils";
import GPS from '@/utils/address';
import vehiclePicture from '@/utils/vehiclePicture';
import { mapState } from "vuex";

export default {
  // `name`与路由保持一致
  name: "Track",
  components: {
    GroupTree,
    SelectGroupTree,
    Split,
    MapTrack,
    AMap,
    InfoWindow,
    MapClusterer,
    AddressText,
  },
  props: {
    iProp: {
      // 传入参数
      type: Object,
      default() {
        return {};
      },
    },
    loadStatus: {
      // 加载状态 true:渲染组件加载轨迹， false: 点击搜索加载数据
      type: Boolean,
      default: false,
    },

    inputDisabled: {
      // 车辆输入框是否可选
      type: Boolean,
      default: false,
    },
    selecteds: {
      //已选择的数据 用于修改功能 仅多选可用
      type: Array,
      default() {
        return []; // { label:车牌/组织, id:车辆ID/车组ID }
      },
    },
  },

  data() {
    return {
      mapCenter: [116.482169, 39.998932],
      defaultIcon: require("@/assets/images/默认停车.png"),
      activeName: "1", // tabs激活的name
      searchVisible: true, // 搜索条件的显隐
      percentage: 0, //播放进度
      stopStatus: true, // 是否过滤停车数据? true:过滤, false:不过滤
      isStart: false,

      // 正在搜索状态
      isSearching: false,
      conditionObj: {
        vehicleId: 0, // 车辆id
        filterTime: 5, // 停车时间
        posStatus: 1, // 是否过滤不定位数据,0:过滤不定位轨迹,1:全部,2：过滤定位轨迹
        plate: "",
        stopStatus: 0,
        beginTime: "",
        endTime: "",
        stopFlag: 0, //是否过滤行驶数据
      },

      trackProps: {
        // 回放参数
        path: [],
      },

      dataList: [], // 记录初始数据
      tableData: [], // table 数据
      height: 0, // 虚拟table高度 必须要
      rowHeight: 55, // 默认行高
      columns: this.initColumns(), // table表格表头
      infoProps: {
        // 窗体参数
        time: "",
        visible: false,
        lngLat: [114.340716, 23.012966],
        params: {},
        lon: "",
        lat: "",
        address: "",
      },
      windowPoint: [],

      // 播放速度
      speedValue: 1200,
      carInfo: {}
    };
  },
  computed: {
    ...mapState(["userConfig"]),
    markerOpts() {
      return {
        offset: [-2, 8],
      };
    },
  },

  beforeMount() {
    this.scrollIntoView = throttle(this.scrollIntoView, 200).bind(this);
  },
  mounted() {
    this.initHeight(); // 动态设置高度
    this.changeDate(0); // 时间快捷键
    this.checkLoadStatus(); // 监测加载状态
    // 获取系统设置的值
    // 停车时间
    this.conditionObj.filterTime = this.userConfig.trackConfig.parkingTime;
    // 过滤停车轨迹
    this.stopStatus = this.userConfig.trackConfig.filterParkingChecked;
    // 定位数据
    this.conditionObj.posStatus = this.userConfig.trackConfig.positioningState;
    // 默认播放速度
    this.speedValue = this.userConfig.trackConfig.trackPlaySpeed;

    this.setTime();
  },
  methods: {
    setSearchLoading(loading) {
      this.isSearching = loading;
    },
    // 设置时间
    setTime() {
      if (this.iProp.filterTime) {
        this.conditionObj = {
          ...this.iProp,
          stopStatus: this.iProp.stopStatus ? "0" : "1",
        };

        this.stopStatus = this.iProp.stopStatus;
      }
    },

    // 监测加载状态
    checkLoadStatus() {
      if (!this.loadStatus) return;
      this.dataList = [];
      this.tableData = [];
      this.trackProps.path = [];
      this.infoProps.visible = false;
      this.percentage = 0;

      const { selecteds } = this;
      if (selecteds && selecteds.length) {
        const { id, label } = selecteds[0];
        this.conditionObj.vehicleId = id;
        this.conditionObj.plate = label;
      }


      const params = {
        ...this.iProp,
        stopStatus: this.iProp.stopStatus ? "0" : "1",
      };

      this.getTrackInfoClick(params);
    },

    //tabs 切换事件
    handleClick(tab) {
      this.pauseMove();
      switch (tab.name) {
        case "1":
          this.activeName = "1";
          this.tableData = this.dataList;
          break;
        case "2":
          this.activeName = "2";
          this.tableData = this.dataList.filter(
            (item) => item.stopStatus === 2
          );
          break;
        case "3":
          this.activeName = "3";
          this.tableData = this.dataList.filter((item) => item.isStop === 0);
          break;
      }
    },

    // 时间快捷键事件
    changeDate(type) {
      const start = new Date();

      start.setTime(start.getTime() - 3600 * 1000 * 24 * type);

      this.conditionObj.beginTime = dayjs(new Date(start)).format(
        "YYYY-MM-DD 00:00:00"
      );

      if (type === 1) {
        this.conditionObj.endTime = dayjs(new Date(start)).format(
          "YYYY-MM-DD 23:59:59"
        );
      } else {
        this.conditionObj.endTime = dayjs(new Date()).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
    },

    // 动态设置高度
    initHeight() {
      this.$nextTick(() => {
        const { umyTable } = this.$refs;
        this.height = umyTable.clientHeight;
      });
    },

    // 虚拟表格 表头
    initColumns() {
      return [
        {
          label: "设备名称",
          prop: "plate",
          width: 100,
        },
        {
          label: "车组名",
          prop: "groupName",
          width: 150,
        },
        {
          label: "终端编号",
          prop: "terminalNo",
          width: 100,
        },
        {
          label: "终端类型",
          prop: "terminalType",
          width: 100,
        },
        {
          label: "行停状态",
          prop: "stopStatusStr",
          width: 80,
        },
        {
          label: "补传数据",
          prop: "supplyDataStr",
          width: 80,
        },
        {
          label: "信号强度",
          prop: "signalStrength",
          width: 80,
        },
        {
          label: "传感器速度",
          prop: "sensorSpeed",
          width: 80,
        },
        {
          label: "相对里程",
          prop: "positionMileage",
          width: 80,
        },
        {
          label: "里程",
          prop: "mileage",
          width: 80,
        },
        {
          label: "定位状态",
          prop: "isStopStr",
          width: 80,
        },
        {
          label: "GPS速度",
          prop: "gpsSpeed",
          width: 80,
        },
        {
          label: "收星数",
          prop: "gnssNum",
          width: 80,
        },
        {
          label: "驾驶员",
          prop: "driver",
          width: 80,
        },
        {
          label: "方向",
          prop: "directionStr",
          width: 80,
        },
        // {
        //   label: "电量",
        //   prop: "voltage",
        //   width: 120,
        // },
        {
          label: "设备时间",
          prop: "devTime",
          width: 230,
        },
        {
          label: "报警类型",
          prop: "alarmTypeCh",
          width: 100,
        },
        {
          label: "报警来源",
          prop: "alarmSourceStr",
          width: 100,
        },
        {
          label: "ACC状态",
          prop: "accStateStr",
          width: 80,
        },
        {
          label: "经度",
          prop: "lon",
          width: 120,
        },
        {
          label: "纬度",
          prop: "lat",
          width: 120,
        },
      ];
    },

    // 搜索条件显隐事件
    changeSearch() {
      this.searchVisible = !this.searchVisible;
    },
    // 车辆搜索框选择事件
    onSelect(node) {
      this.activeName = "1";
      this.conditionObj.vehicleId = node.vehicleId || 0;
      this.conditionObj.plate = node.plate || "";
      this.carInfo = node;
      this.$umaApi.umaVehicleSelection('搜索选择车辆', node);
    },

    // 选择车辆事件
    onConfirm(node) {
      this.activeName = "1";
      this.conditionObj.vehicleId = node.vehicleId || 0;
      this.conditionObj.plate = node.plate || "";
      this.carInfo = node;
      this.$umaApi.umaVehicleSelection('选择车辆', node);
    },

    //传参校验
    dataValidate() {
      const { vehicleId, filterTime } = this.conditionObj;
      if (filterTime < 0) {
        this.$message.error("停车时间必须大于0");
        return false;
      }
      if (!vehicleId) {
        this.$message.error("请选择车辆");
        return false;
      }
      return true;
    },
    // 查询点击事件
    searchClick() {
      this.isStart = false;
      this.$refs.mapTrackRef.stopMove();

      this.dataList = [];
      this.tableData = [];
      this.trackProps.path = [];
      this.infoProps.visible = false;
      this.percentage = 0;

      if (!this.dataValidate()) return;

      const params = {
        ...this.conditionObj,
        stopStatus: this.stopStatus ? 0 : 1,
      };

      this.getTrackInfoClick(params);
    },

    async getTrackInfoClick(params) {

      try {
        this.setSearchLoading(true);
        const result = await getTrackInfo({
          ...params,
          filterTime: params.filterTime * 1,
        });
        this.activeName = "1";

        if (!(result.flag && result.rows)) {
          return this.$message.warning(result.msg);
        }

        if (!result.rows.length) {
          return this.$message.warning('--');
        }

        const { extend } = result;

        this.tableData = result.rows.map(item => ({
          ...item,
          plate: extend.plate,
          groupName: extend.groupName,
          vehicleShape: extend.vehicleShape,
          terminalNo: extend.terminalNo,
          terminalType: extend.terminalType,
          address: '',
        }));

        // 经纬度转换地址
        const points = result
          .rows
          .map((item, index) => ({
            lon: item.lon,
            lat: item.lat,
            tag: index
          })).filter(item => item.lon != 0);

        this.setAddresses(points, 0);

        this.dataList = this.tableData; // 记录查询的初始数据
        let path = result.rows.filter(val => ((val.lng != 0) && (val.lon != 0)));
        this.trackProps.path = path.map(item => {
          const point = GPS.delta(item.lat, item.lon);
          return {
            lng: point.lon,
            lat: point.lat
          };
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.setSearchLoading(false);
      }

    },

    async setAddresses(points, type) {
      const result = await getAddresses(points);
      result.forEach(({ tag, address }) => {
        this.dataList[tag][["address", "address1"][type]] = address;
      });
    },

    // 播放轨迹点击事件
    async playTrack() {

      const { mapTrackRef } = this.$refs;

      if (!this.isStart || this.percentage === 100) {
        await mapTrackRef.resetMove();

        mapTrackRef.startMove();
      }

      this.isStart = true;

      if (this.isStart) mapTrackRef.resumeMove();
    },

    // 暂停播放
    pauseMove() {
      const { mapTrackRef } = this.$refs;
      mapTrackRef.pauseMove();
    },

    /**
     * 计算播放进度
     * moveToIndex: 下一个位置下标
     */
    movingClick(passedPath, moveToIndex, e) {
      const { tableData } = this;

      if (moveToIndex < tableData.length) {
        this.windowPoint = passedPath[passedPath.length - 1];
        this.setMoveMarker(moveToIndex - 1);
      }
    },
    onMoveEnd() {
      const { tableData, trackProps } = this;
      // TODO: 实现播放结束

      this.isStart = false;

      this.windowPoint = trackProps[trackProps.path.length - 1];
      this.setMoveMarker(tableData.length - 1);
    },
    setMoveMarker(index) {
      const { tableData } = this;
      if (index < tableData.length) {

        // 移动的位置点信息
        const pointObj = tableData[index];
        this.infoProps.params = pointObj;
        this.infoProps.lngLat = [pointObj.lon, pointObj.lat];
        this.infoProps.address = pointObj.address;
        this.infoProps.visible = true;

        const percentage = Math.floor(((index + 1) / tableData.length) * 10000) / 100;
        this.percentage = percentage;

        this.scrollIntoView(pointObj, index);
      }
    },
    /**
     * table 设置当前行, 并滚动到可视区
     */
    async scrollIntoView(row, index) {
      const { rowHeight } = this;
      const { uTableObj } = this.$refs;
      if (!uTableObj) return;

      // 距离顶部高度
      const top = Math.max(0, (index + 0) * rowHeight);

      uTableObj.pagingScrollTopLeft(top, 0);
      await this.$nextTick();
      uTableObj.setCurrentRow(row);
    },
    changeInput(value) {
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符

      value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是.

      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的.

      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");//只允许输入一个小数点

      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
      this.conditionObj.filterTime = value;
    },
    vehicleIconName(val) {
      if (this.carInfo.machineryProductType) {
        return vehiclePicture(this.carInfo.machineryProductType, this.carInfo.machineryEquipmentType, val.Z, true);
      } else {
        return val;
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.track {
  width: 100%;
  height: calc(100vh - 63px);
  position: relative;
  user-select: none;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;

    padding: 20px;
    flex-wrap: wrap;

    & > div {
      margin: 20px;
      width: 500px;
      height: 400px;
    }
    .footer {
      height: 100%;
      background: #ffffff;
      color: #333;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
}
.search {
  position: absolute;
  left: 65px;
  top: 16px;
  z-index: 20;
}
.play-pane {
  // width: 400px;
  display: flex;
  background: #ffffff;
  position: absolute;
  z-index: 30;
  right: 78px;
  top: 15px;
  padding: 7px 10px;
  .speed-str {
    display: inline-block;
    width: 46px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #1890ff;
  }
  & > label {
    font-size: 12px;
    display: inline-block;
    width: 55px;
    line-height: 32px;
  }

  .el-button {
    padding: 0 8px !important;
  }
}
.search-pane {
  position: absolute;
  width: 365px;
  height: auto;
  background: #ffffff;
  padding: 15px;
  top: 57px;
  left: 88px;
  z-index: 999;
  .search-pane-item {
    display: flex;
    width: 100%;
    margin: 8px 0;
    & > label {
      font-size: 14px;
      text-align: left;
      color: #0c0c0c;
      line-height: 30px;
      margin: 0 10px 0 0;
    }
    .el-button {
      font-size: 14px;
      line-height: 0;
    }
    .parking-item {
      display: flex;
      line-height: 30px;
      & > a {
        text-decoration: underline;
      }
      .el-input {
        width: 70%;
        margin: 0 6px;
      }
    }
  }
  .search-btn {
    text-align: right;
  }
}

.table-umy {
  height: calc(100% - 60px);
}
::v-deep .plTableBox {
  height: 100%;
}

::v-deep .el-input__inner {
  line-height: 1px !important;
}
.window-title {
  width: 100%;
  height: 31px;
  color: #ffffff;
  line-height: 31px;
  padding: 0 15px;
  font-size: 14px;
  background: #212d3e;
}
::v-deep .el-icon-close {
  color: #ffffff;
}
.window-info {
  color: #a4a4a4;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
  & > label {
    display: inline-block;
    width: 50%;
    font-size: 12px;
    line-height: 30px;
    text-align: left;
    & > strong {
      color: #000000;
    }
  }
}

::v-deep .el-progress {
  width: 56%;
  line-height: 30px;
  .el-progress-bar {
    width: 95%;
  }
}
</style>