<template>
  <div class="item">
    <div class="item-map"
      @click="maxClick">
      <AMap :center="mapCenter"
        :zoom="17">
        <!-- 轨迹回放 -->
        <MapTrack ref="mapTrackRef"
          :path="trackProps.path"
          :speed="trackProps.speed"
          :markerOpts="markerOpts"
          :startMarkerOpts="markerOpts"
          :endMarkerOpts="markerOpts">
          <template #startMarker>
            <Iconfont name="icon-qi"
              :size="32"></Iconfont>
          </template>
          <template #endMarker>
            <Iconfont name="icon-zhong"
              :size="32"></Iconfont>
          </template>
        </MapTrack>
      </AMap>
    </div>

    <div class="item-footer">
      <label v-if="status">{{iProp.plate || '--'}}</label>
      <label v-if="status"
        style="text-align:right;color:#0063BF">
        <!-- {{ extend.positionMileage === 0 ? "无轨迹" : `${extend.positionMileage } KM`}} -->
        <!-- {{ trackProps.path && trackProps.path.length === 0 ? "无轨迹" : '有轨迹'}} -->
          {{ trackProps.path && trackProps.path.length === 0 ? "无轨迹" : (extend.positionMileage == 0 ? "有轨迹":`${extend.positionMileage } KM`)}}
      </label>
    </div>

    <el-dialog :title="conditionObj.plate"
      :visible.sync="trackVisible"
      width="99%">
      <Track v-if="trackVisible"
        style="height:calc(100vh - 55px);"
        :iProp="trackiProp"
        :inputDisabled="true"
        :selecteds="[{label:iProp.plate,id:vehicleId}]"
        :loadStatus="true" />
    </el-dialog>
  </div>
</template>

<script>
import AMap, { MapTrack } from '@/components/AMap';
import { getTrackInfo } from '@/api/getManagementData.js';
import Track from '@/components/Track';

export default {
  name: 'TrackModal',
  components: {
    AMap,
    MapTrack,
    Track
  },
  props: {
    iProp: { // 搜索参数
      type: Object,
      default() {
        return {};
      },
    },

    vehicleId: { // 车辆id
      type: Number,
      default: 0
    },

    status: { // 查询状态
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mapCenter: [116.482169, 39.998932],
      trackProps: {
        path: [],
        speed: 1000,
      },

      extend: {
        plate: '',
        totalMileage: 0,
        positionMileage: 0,
      },

      trackVisible: false,
      conditionObj: {},

      trackiProp: {},
    };
  },

  watch: {
    status: {
      handler: function (val) {
        if (val) {
          this.trackProps.path = [];
          this.conditionObj = {
            ...this.iProp,
            vehicleId: this.vehicleId,
          };
          this.getTrackList();
        }
      }
    }
  },
  computed: {
    markerOpts() {
      return {
        offset: [-2, 8],
      };
    },
  },
  methods: {
    // 轨迹查询接口
    async getTrackList() {

      this.extend = {
        plate: '',
        totalMileage: 0,
        positionMileage: 0,
      };
      const result = await getTrackInfo({
        ...this.conditionObj,
        stopStatus: this.conditionObj.stopStatus ? '0' : '1  ',
      });
      if (!(result.flag && result.rows.length)) return;
      // 经纬度转换地址
      const points = result.rows.map((item, index) => ({
        lon: item.lon,
        lat: item.lat,
        tag: index
      })).filter(item => item.lon != 0);
      this.trackProps.path = points.map(item => ({ lng: item.lon, lat: item.lat }));
      this.extend = result.extend;
      const temp = this.trackProps.path;
      if (temp.length) {
        this.mapCenter = [temp[0].lng, temp[0].lat];
      }
    },

    // 窗口最大化点击事件
    maxClick() {
      if (this.status) {
        this.trackVisible = true;
        this.trackiProp = {
          ... this.iProp,
          vehicleId: this.vehicleId
        };
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.item {
  text-align: center;
  border-right: 10px solid #f3f3f3;
  border-bottom: 10px solid #f3f3f3;
  background: #ffffff;
  padding: 5px;
  position: relative;
  height: 100%;
  width: 100%;
  .item-map {
    width: 100%;
    height: calc(100% - 55px);
    margin: 0 0 5px 0;
    background: #ccc;
  }
  .item-footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: -webkit-linear-gradient(left, #d2e4f5, #ffffff);
    & > label {
      display: inline-block;
      width: 50%;
      text-align: left;
      padding: 0 15px;
      color: #202020;
      font-weight: 700;
      box-sizing: border-box;
    }
  }
}
.item:nth-child(3n-1) {
  .item-footer {
    background: -webkit-linear-gradient(left, #f6ddcc, #ffffff);
  }
}
.item:nth-child(3n) {
  border-right: none;
  .item-footer {
    width: 100%;
    height: 50px;
    background: -webkit-linear-gradient(left, #cce5e2, #ffffff);
  }
}

::v-deep .el-dialog {
  margin: 5px 8px !important;
  .el-dialog__header {
    padding: 10px;
    text-align: left;
    background: #1890ff;
    .el-dialog__headerbtn {
      top: 13px;
      .el-dialog__close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>