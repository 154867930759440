<template>
  <el-container>
    <el-header height="60px">
      <div class="filter-pane">
        <label>选择车辆</label>
        <SelectGroupTree ref="groupTree"
          style="width:250px"
          :multipleProp="{ noGroup:true,vehicleCheckBox:true }"
          :inputGroup="false"
          :defaultCheck="false"
          normal
          alone="vehicle"
          :limit="limit"
          placeholder="车辆名称/设备名称"
          @on-select="onSelect"
          @on-check="nodeCheck"
          @on-channel="channelClick" />

        <label class="interval">选择日期</label>
        <el-date-picker style="width:250px"
          v-model="pickerTime"
          type="daterange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">></el-date-picker>

        <label class="interval">停车时间:</label>
        <a>></a>
        <el-input type="number"
          style="width:60px"
          maxlength="9"
          :disabled="!conditionObj.stopStatus"
          v-model="conditionObj.filterTime"></el-input>
        <label>分钟</label>

        <label class="interval">定位数据:</label>
        <el-select style="width:130px"
          v-model="conditionObj.posStatus"
          placeholder="请选择">
          <el-option label="全部"
            :value="1"></el-option>
          <el-option label="过滤不定位轨迹"
            :value="0"></el-option>
          <el-option label="过滤定位轨迹"
            :value="2"></el-option>
        </el-select>

        <el-checkbox class="interval"
          v-model="conditionObj.stopStatus">过滤停车轨迹</el-checkbox>
        <el-button class="interval"
          type="primary"
          @click="searchClick">查询</el-button>
        <el-button class="interval"
          type="primary"
          @click="resetClick">重置</el-button>
      </div>
    </el-header>
    <el-main>
      <div class="multi-container">
        <div style="height:100%"
          v-for="item in selectVehicles"
          :key="item.vehicleId">
          <TrackModal :iProp="{...trackiProp,plate:item.plate}"
            :vehicleId="item.vehicleId"
            :status="searchStatus" />
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import TrackModal from './TrackModal';
import moment from 'dayjs';

export default {
  // `name`与路由保持一致
  name: 'MultiVehicleTrack',
  components: {
    SelectGroupTree,
    TrackModal
  },
  data() {
    return {
      selectVehicles: [], // 已选择的车辆 （根据数据渲染页面容器）
      pickerTime: '',  //日期选择参数
      conditionObj: { // 搜索条件
        // vehicleList: [],
        beginTime: '',//开始时间
        endTime: '', //结束时间
        posStatus: 1, //是否过滤不定位数据
        stopStatus: false,//是否过滤停车数据
        filterTime: '5',//停车时间
        plate: '',
      },
      trackiProp: {}, //轨迹搜索参数
      pickerOptions: this.setPickerOptions(),  // 设置不能选择的日期

      searchStatus: false, // 是否触发搜索事件
      limit: 6,//最大可选车辆
    };
  },
  watch: {
    pickerTime: {
      handler(val) {
        val.map((time, index) => {
          if (!index) return this.conditionObj.beginTime = moment(new Date(time)).format("YYYY-MM-DD 00:00:00");

          this.conditionObj.endTime = moment(new Date(time)).format("YYYY-MM-DD 23:59:59");
        });
      }
    },
    conditionObj: {
      deep: true,
      handler(val) {
        this.searchStatus = false;
      },
    },
  },
  mounted() {
    const date = moment(new Date()).format("YYYY-MM-DD");
    this.pickerTime = [date, date];
  },
  methods: {
    // 设置不能选择的日期
    setPickerOptions() {
      return {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
          (time) => {
            let choiceDateTime = new Date(this.choiceDate0).getTime();
            const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
            const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
            const min = minTime;
            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            const max = newDate < maxTime ? newDate : maxTime;
            //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
            // if (this.choiceDate0) {
            //   return time.getTime() < min || time.getTime() > max;
            // }
            //若一个日期也没选中 则 返回 当前日期以前日期可选
            return time.getTime() > newDate;
          }
      };
    },

    // 车辆搜索框选择事件
    onSelect(node) {
      const temp = this.selectVehicles.filter(p => p.vehicleId === node.vehicleId);
      if (temp.length > 0) {
        return this.$message.warning("不能重复选择车辆");
      }

      if (this.selectVehicles.length >= this.limit) {
        return this.$message.warning(`最多只可选择${ this.limit }台设备！`);
      }

      this.searchStatus = false;
      this.selectVehicles.push(node);

    },
    // 车辆勾选事件
    nodeCheck(data) {
      this.searchStatus = false;
      this.selectVehicles = data;
    },

    channelClick() {
      this.selectVehicles = [];
    },

    // 搜索点击事件
    searchClick() {
      this.searchStatus = false;
      const { filterTime } = this.conditionObj;
      if (!this.selectVehicles.length) return this.$message.warning("请选择车辆");
      if (filterTime === '') return this.$message.warning("停车时间不能为空");

      this.trackiProp = { ...this.conditionObj };

      this.searchStatus = true;
    },

    resetClick() {
      this.selectVehicles = [];
      const { groupTree } = this.$refs;
      groupTree.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  position: relative;
}
.el-header {
  position: relative;
  width: 98.5%;
  margin: 10px auto;
  background-color: #ffffff;
  color: #333;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  .filter-pane {
    display: flex;
    align-items: center;
    margin: 8px 0;
    & > label {
      margin: 0 6px 0 5px;
      font-size: 14px;
    }
  }
}
.el-main {
  width: 98.5%;
  margin: auto;
  height: calc(100vh - 143px);
  background-color: #ffffff;
  color: #333;
  padding: 0;
  box-sizing: border-box;
}

.multi-container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fill, 33.3%);
}

.interval {
  margin: 0px 5px 0px 20px !important;
}
</style>